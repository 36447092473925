<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>
    <div id="app-content">
      <div class="page-item audit">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderDesc',
  data() {
    return {

    }
  },
  created() { },
  methods: {}
}
</script>

<style lang='scss' scoped>
</style>